<template>
  <div class="relative overflow-hidden">
    <div class="bg-white pb-5 pt-5 sm:pt-5 lg:overflow-hidden lg:pb-5 lg:pt-5">
      <div class="mx-auto max-w-5xl lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8">
          <div
            class="mx-auto max-w-md px-4 text-left sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left"
          >
            <div class="lg:py-24">
              <h1
                class="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl"
              >
                <span class="block text-gray-900"
                  ><img
                    alt="me"
                    src="~/assets/logo192.png"
                    width="128"
                    height="128"
                    decoding="async"
                    data-nimg="1"
                    class="mb-5 inline"
                    loading="lazy"
                    style="color: transparent"
                  /><span style="margin-left: -24px">omeKiq</span></span
                >
              </h1>
              <p class="text-xl text-gray-600">
                We are working hard to reduce the hassle of home ownership and
                are thrilled to include you on our journey
              </p>
              <div class="mt-4 text-base font-light text-gray-600 sm:mt-5">
                Home owners in Westchester, NY and Fairfield County, CT can
                download our app
              </div>
              <div class="flex h-16 items-center gap-4 py-2">
                <a
                  href="https://apps.apple.com/us/app/homekiq/id1668528287?itsct=apps_box_promote_link&itscg=30200"
                >
                  <AppleButtonIcon />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.homekiq.customerportal&hl=en_US&gl=US&utm_source=homekiq.com&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                >
                  <img
                    alt="Get it on Google Play"
                    class="h-16 py-0.5"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </div>
              <div class="mt-4 rounded border p-4 shadow">
                <p class="text-base font-light text-gray-600">
                  Please join our waitlist to get early access to HomeKiq when
                  it is available in your neighborhood.
                </p>
                <div class="mt-4">
                  <WaitlistForm />
                </div>
              </div>
            </div>
          </div>
          <div class="ml-auto mt-5 hidden lg:block">
            <img
              alt="homekiq"
              src="~/assets/screenshot.webp"
              width="320"
              height="670"
              decoding="async"
              data-nimg="1"
              loading="lazy"
              style="color: transparent"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center"></div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
useSeoMeta({
  title: "HomeKiq - Reducing the stress of Home ownership",
  description:
    "HomeKiq is a platform that helps homeowners stay on top of home maintenance and repairs by telling them what to do when and also connecting them to a network of vetted professionals and gig workers to take care of anything they need",
  appleItunesApp: { appId: "1668528287" },
  appleMobileWebAppCapable: "yes",
  ogImage: "/og_image.png",
  twitterCard: "summary_large_image",
});

onMounted(async () => {
  if (route.query.download) {
    await redirectToAppStore();
  }
});

function getMobileOS() {
  var userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "unknown";
}

async function redirectToAppStore() {
  let os = getMobileOS();
  if (os == "Android") {
    await navigateTo(
      "https://play.google.com/store/apps/details?id=com.homekiq.customerportal&amp;hl=en_US&amp;gl=US&amp;utm_source=homekiq.com&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
      { external: true },
    );
  } else if (os == "iOS") {
    await navigateTo(
      "https://apps.apple.com/us/app/homekiq/id1668528287?itsct=apps_box_promote_link&amp;itscg=30200",
      { external: true },
    );
  } else {
    await navigateTo("/");
  }
}
</script>
